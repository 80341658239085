import React, {
    useEffect, useCallback, useContext, useState
} from 'react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import {
    useHistory
} from 'react-router-dom';
import {
    useErrorHandler,
    TECHNICAL_ERROR_CODE,
    QB_STEPS,
    QUOTE_RETRIEVAL_STEPS,
    AppContext
} from 'nfum-portals-utils-react';
import { NfumAccordionHeader } from 'nfum-components-platform-react';
import useCleanPayload from '../../hooks/useCleanPayload';
import metadata from './DirectDebitPage.metadata.json5';
import messages from './DirectDebitPage.messages';
import styles from './DirectDebitPage.module.scss';
import SectionComponent from '../../components/SectionComponent/SectionComponent';
import ReferralMessageValidation from '../../components/ReferralMessageComponent/ReferralMessageComponent';
import DirectDebitGuarantee from './DirectDebitGuarantee/DirectDebitGuarantee';
import useTagManager from '../../hooks/useTagManager';

function DirectDebitPage(props) {
    const viewModelService = useContext(ViewModelServiceContext);
    const {
        wizardData: submissionVM, updateWizardData, goNext, steps, jumpTo
    } = props;
    const { onValidate, isComponentValid } = useValidation('DirectDebitPage');
    const { authHeader } = useAuthentication();
    const { LoadSaveService } = useDependencies('LoadSaveService');
    const translator = useContext(TranslatorContext);
    const [totalPremiumcost, updateTotalPremiumcost] = useState('');
    const [isAutomaticRenewalOptionLoading, setIsAutomaticRenewalOptionLoading] = useState(false);
    const { isQuoteRetrieval } = useContext(AppContext);
    const { handleError } = useErrorHandler();
    const { cleanNotUpdatedCoverages, cleanCostInfos } = useCleanPayload();
    const {
        pushFormStepInfo,
        pushFormStepErrorInfo,
        pushRelativeLinkClickInfo
    } = useTagManager();
    const [isRenewalOptedOut, updateRenewalOptedOut] = useState('');
    const cardsIds = Object.freeze({
        PAYMENTSCH_CARD: 'paymentScheduleCard',
    });
    const producerDesc = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeDescription', '');
    const producerTele = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.producerCodeTelephone', '');
    const isBespoke = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.isBespoke', '');
    const showAgencyDetails = _.get(submissionVM.value, 'baseData.producerDetails_NFUM.showProducerDetails', '');
    const history = useHistory();
    const isDDQuestionConfirmed = _.get(submissionVM.value, 'bindData.paymentDetails.ddquestionConfirmation');

    useEffect(() => {
        _.set(submissionVM.value, 'bindData.paymentDetails.bankAccountData.isValidationNotRequired_NFUM', false);
        _.set(submissionVM.value, 'bindData.paymentDetails.paymentMethod', 'directdebit');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        pushFormStepInfo(submissionVM, isQuoteRetrieval
            ? QUOTE_RETRIEVAL_STEPS.PAYMENT
            : QB_STEPS.PAYMENT);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const totalPremiumcostValue = _.get(submissionVM, 'quoteData.offeredQuotes.value[0].premium.total');
        updateTotalPremiumcost(totalPremiumcostValue?.amount);
    }, []);

    const handleNextStep = useCallback(async () => {
        try {
            await LoadSaveService.verifyBankAccountData(
                submissionVM.value.quoteID,
                submissionVM.value.sessionUUID,
                submissionVM.value.bindData.paymentDetails.bankAccountData,
                authHeader
            );
        } catch (error) {
            if (error.appErrorCode === TECHNICAL_ERROR_CODE) {
                history.push('/service-unavailable?ddPaymentError=true');
            }
            handleError(error);
            return false;
        }
        try {
            cleanNotUpdatedCoverages(submissionVM);
            cleanCostInfos(submissionVM);
            submissionVM.value = await LoadSaveService.bindSubmission(
                submissionVM.value,
                authHeader
            );
        } catch (error) {
            handleError(error);
            return false;
        }

        return submissionVM;
    }, [
        submissionVM,
        LoadSaveService,
        authHeader,
        handleError,
        history,
        cleanNotUpdatedCoverages,
        cleanCostInfos
    ]);

    const ddAutomaticRenewalOptionChange = useCallback(async (value, path) => {
        setIsAutomaticRenewalOptionLoading(true);

        // create newSubmission just to send the chosen option - don't mix with the current submission
        const newSubmissionVM = viewModelService.clone(submissionVM);
        _.set(newSubmissionVM.value, path, value);
        _.set(newSubmissionVM.value, 'bindData.paymentDetails.bankAccountData.isValidationNotRequired_NFUM', true);
        cleanNotUpdatedCoverages(newSubmissionVM);
        cleanCostInfos(newSubmissionVM);

        // update current submission
        updateRenewalOptedOut(value);
        _.set(submissionVM.value, path, value);
        updateWizardData(submissionVM);

        // send chosen option
        try {
            await LoadSaveService.updateQuotedSubmission(
                newSubmissionVM.value,
                authHeader
            );
        } catch (e) {
            setIsAutomaticRenewalOptionLoading(false);
            pushFormStepErrorInfo(newSubmissionVM, isQuoteRetrieval
                ? QUOTE_RETRIEVAL_STEPS.PAYMENT
                : QB_STEPS.PAYMENT, e);
            handleError(e, newSubmissionVM.value.quoteID);
        } finally {
            setIsAutomaticRenewalOptionLoading(false);
        }
    }, [
        viewModelService,
        submissionVM,
        updateWizardData,
        LoadSaveService,
        authHeader,
        pushFormStepErrorInfo,
        isQuoteRetrieval,
        handleError,
        cleanNotUpdatedCoverages,
        cleanCostInfos]);

    const handlePremiumCostValue = (value) => {
        let monthlyValue = value / 12;
        monthlyValue = monthlyValue.toFixed(2);
        return `£${monthlyValue}`;
    };

    const skipInitialValidation = () => {
        return true;
    };

    const handleTermsSelector = useCallback((value, path) => {
        const newSubmissionVm = _.clone(submissionVM);
        _.set(newSubmissionVm, path, value);
        updateWizardData(newSubmissionVm);
    }, [submissionVM, updateWizardData]);

    const prepareBillingAddress = useCallback(() => {
        const {
            addressLine1, addressLine2, addressLine3, city, county, postalCode
        } = submissionVM.baseData.accountHolder.primaryAddress;

        let firstLine = '';
        if (addressLine1.value) {
            firstLine += `${addressLine1.value} `;
        }
        if (addressLine2.value) {
            firstLine += `${addressLine2.value} `;
        }
        if (addressLine3.value) {
            firstLine += `${addressLine3.value}`;
        }

        return (
            <>
                {firstLine && (
                    <>
                        <span>{firstLine}</span>
                        <br />
                    </>
                )}
                {city.value && (
                    <>
                        <span>{city.value}</span>
                        <br />
                    </>
                )}
                {county.value && (
                    <>
                        <span>{county.value}</span>
                        <br />
                    </>
                )}
                {postalCode.value && <span>{postalCode.value}</span>}
            </>
        );
    }, [submissionVM.baseData.accountHolder.primaryAddress]);

    useEffect(() => {
        if (submissionVM.baseData.paymentMethod_NFUM.value.code === 'cash') {
            goNext();
        }
    }, [goNext, submissionVM.baseData.paymentMethod_NFUM]);

    function MoneyCell({ data }) {
        return (
            <span className={styles.tableCell}>
                £
                {data.value}
            </span>
        );
    }
    function Cell({ data }) {
        return (
            <span className={styles.tableCell}>
                {data.value}
            </span>
        );
    }

    const cellRenderers = {
        money: MoneyCell
    };

    const handleRenderCell = useCallback((data, index, tableProps) => {
        const { path } = tableProps;
        const cellData = data[path];
        const CellRenderer = cellRenderers[_.toLower(cellData.valueType)] || Cell;
        return (
            <CellRenderer
                data={cellData}
                wizardData={submissionVM}
            />
        );
    }, [submissionVM]);

    const handleCancelTransaction = useCallback(() => {
        const url = '/policy-summary';
        pushRelativeLinkClickInfo(translator(messages.cancelTransaction), url);
        const indexOfQuoteSummary = _.findIndex(
            steps,
            ({ path }) => path === url
        );
        jumpTo(indexOfQuoteSummary);
    }, [steps, jumpTo, pushRelativeLinkClickInfo, translator]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onValidate: onValidate,
            onRenderCell: handleRenderCell,
        },
        resolveComponentMap: {
            sectionComponent: SectionComponent,
            referralMessage: () => (
                <ReferralMessageValidation
                    title={translator(messages.unableToTakePayment)}
                    description={translator(messages.unableToProcessContent)}
                />
            ),
            directDebitGuarantee: DirectDebitGuarantee
        }

    };

    const renderAccordionHeader = (isOpen, title, openMessage) => {
        return (
            <NfumAccordionHeader
                isOpen={isOpen}
                title={title}
                openMessage={openMessage}
                showCloseMessage
            />
        );
    };

    const mapScheduleRowData = ((installment, index) => ({
        id: `${index}-${installment?.installmentDate}`,
        date: { value: `${installment?.installmentDate?.day}/${installment?.installmentDate?.month + 1}/${installment?.installmentDate?.year}` },
        premium: { value: installment?.installmentAmount?.amount?.toFixed(2), valueType: 'money' }
    }));

    const generatePaymentScheduleRowData = useCallback(() => {
        const schedulePath = 'bindData.paymentPlans.children[0].installmentDetails_NFUM.value';
        const paymentData = _.get(submissionVM, schedulePath);
        if (paymentData) {
            return paymentData.map(mapScheduleRowData);
        }
        return [];
    }, [submissionVM]);

    const paymentScheduleTableData = generatePaymentScheduleRowData();

    const overrideProps = {
        directDebitPageBillingAddress: {
            content: prepareBillingAddress()
        },
        directDebitPageCostValue: {
            content: handlePremiumCostValue(totalPremiumcost)
        },
        directDebitPageSetupDDFormAccountName: {
            visible: isDDQuestionConfirmed === true,
            tooltip: {
                text: translator(messages.accountHolderNameTooltip)
            },
        },
        directDebitPageSetupDDFormAccountNumber: {
            visible: isDDQuestionConfirmed === true,
            tooltip: {
                text: translator(messages.accountNumberTooltip)
            },
        },
        directDebitPageSetupDDFormSortCode: {
            visible: isDDQuestionConfirmed === true,
            tooltip: {
                text: translator(messages.sortCodeTooltip)
            },
        },
        ddAutomaticRenewalOptionContainer: {
            visible: isDDQuestionConfirmed === true
        },
        ddAutomaticRenewalOption: {
            onValueChange: ddAutomaticRenewalOptionChange,
            disabled: isDDQuestionConfirmed === false || isDDQuestionConfirmed === undefined
        },
        instructionStatementContainer: {
            visible: isDDQuestionConfirmed === true
        },
        referralMessageContainer: {
            visible: isDDQuestionConfirmed === false,
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails
        },
        renewYesInfoContentContainer: {
            visible: isRenewalOptedOut === 'true' || isRenewalOptedOut === true
        },
        renewNoInfoContentContainer: {
            visible: isRenewalOptedOut === 'false' || isRenewalOptedOut === false
        },
        [cardsIds.PAYMENTSCH_CARD]: {
            renderHeader: (isOpen) => {
                return renderAccordionHeader(isOpen, messages.paymentSchedule);
            }
        },
        paymentScheduleTable: {
            data: paymentScheduleTableData
        }
    };

    return (
        <WizardPage
            onNext={handleNextStep}
            showNext
            disableNext={!submissionVM?.bindData?.paymentDetails?.ddquestionConfirmation?.value
                || !isComponentValid
                || isAutomaticRenewalOptionLoading}
            skipWhen={skipInitialValidation}
            showPrevious={false}
            showCancel={false}
            showCancelTransaction
            disableCancelTransaction={isAutomaticRenewalOptionLoading}
            onCancelTransaction={handleCancelTransaction}
            cancelTransactionLabel={messages.cancelTransaction}
            nextLabel={messages.next}
        >
            <ViewModelForm
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                uiProps={metadata.pageContent}
                componentMap={resolvers.resolveComponentMap}
                model={submissionVM}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

DirectDebitPage.propTypes = wizardProps;
export default DirectDebitPage;
