import React, {
    useCallback,
    useEffect,
    useState,
    useContext
} from 'react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import {
    useHistory
} from 'react-router-dom';
import { NfumLoader } from 'nfum-components-platform-react';
import { TranslatorContext } from '@jutro/locale';
import { useAuthentication } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import styles from './ServiceUnavailablePage.module.scss';
import metadata from './ServiceUnavailablePage.metadata.json5';
import messages from './ServiceUnavailablePage.messages';

const ServiceUnavailablePage = () => { // NOSONAR
    const history = useHistory();
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const [jobId, setJobId] = useState('');
    const [producerDesc, setProducerDesc] = useState(undefined);
    const [producerTele, setProducerTele] = useState(undefined);
    const [isBespoke, setIsBspoke] = useState(undefined);
    const [showAgencyDetails, setShowAgencyDetails] = useState(undefined);
    const { PolicyService } = useDependencies('PolicyService');
    const [isLoading, setLoadingState] = useState(false);
    const [ddPaymentError, setDDPaymentError] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const jobID = searchParams.get('jobid');
        setDDPaymentError(searchParams.get('ddPaymentError'));
        setJobId(jobID);
        if (jobID) {
            setLoadingState(true);
            PolicyService.retrieveProducerDetails([jobID, 'quote'], authHeader)
                .then((producerDetails) => {
                    setProducerDesc(producerDetails.producerCodeDescription);
                    setProducerTele(producerDetails.producerCodeTelephone);
                    setIsBspoke(producerDetails.isBespoke);
                    setShowAgencyDetails(producerDetails.showProducerDetails);
                }).finally(() => {
                    setLoadingState(false);
                });
        }
    }, [history, authHeader, PolicyService]);

    const onClose = useCallback(() => {
        history.push('/');
    }, [history]);

    const showAgengyDetails = () => {
        if (jobId === '') {
            return false;
        }
        if (showAgencyDetails) {
            if (!producerDesc || !producerTele) {
                return false;
            }
            return true;
        }
        return false;
    };

    const showContactAgengySection = () => {
        if (jobId === null) {
            return false;
        }
        if (showAgencyDetails) {
            if (!producerDesc || !producerTele) {
                return true;
            }
            return false;
        }
        return false;
    };

    if (isLoading) {
        return <NfumLoader loaded={!isLoading} />; /* NOSONAR: pure declarative usage */
    }
    const overrideProps = {
        referralMessage: {
            producerDesc: producerDesc,
            producerTele: producerTele,
            isBespoke: isBespoke,
            showAgencyDetails: showAgencyDetails,
            showCallBack: true,
            phoneNumber: '0800 208 8836'
        },
        descriptionExtended: {
            visible: !showAgengyDetails() && !isBespoke
        },
        agencyDescriptionExtended: {
            visible: showAgengyDetails() || (showContactAgengySection() && isBespoke)
        },
        closeButton: {
            onClick: onClose
        },
        quoteReference: {
            content: jobId ? `${translator(messages.quoteNumber)}: ${jobId}` : ''
        },
        description: {
            visible: !ddPaymentError
        },
        description2: {
            visible: !ddPaymentError
        },
        description3: {
            visible: (ddPaymentError !== undefined && ddPaymentError)
            || ddPaymentError === true
        },
        description4: {
            visible: (ddPaymentError !== undefined && ddPaymentError)
            || ddPaymentError === true
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
};

export default ServiceUnavailablePage;
